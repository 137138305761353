const settings	=	{
	color:	{
		bg: {
//			light: '#ffcd00',
//			light: 'CornSilk',
//			light: 'RGBa(195,195,186,1)',
			light: 'RGBa(252, 208, 96,1)',
			dark: '#1e1e1e',
		},
		font: {
			dark: '#414042',
			light: '#8e8e8e',
		},
	},
	host:	{
		name: 'blackstonevt.com',
		url: 'https://www.blackstonevt.com',
	},
	social:	{
		facebook:	{
			url:	'https://www.facebook.com/BlackstoneVT/',
		},
		google:	{
			url:	'https://g.page/BlackstoneVT?gm',
		},
		instagram:	{
			url:	'https://www.instagram.com/blackstone.llc/',
		},
		linkedin:	{
			url:	'https://www.linkedin.com/company/blackstone-vt/',
		}
	},
};


settings.select_options_services	=	[
	{
		isGroup: true,
		label: 'Hardscapes🗿',
		options: [
			{value:'hardscape_brick',text:'Brick'},
			{value:'hardscape_concrete_block',text:'Concrete Block'},
			{value:'hardscape_concrete_forms_mixing_pouring',text:'Concrete Forms/Mix/Pour'},
			{value:'hardscape_stone_natural',text:'Natural Stone Laying'},
			{value:'hardscape_stonewalls',text:'Stonewalls'},
			{value:'hardscape_stone_veneer',text:'Stone Veneer'},
		],
	},
	{
		isGroup: true,
		label: 'Landscaping🚜',
		options: [
			{value:'landscape_drainage',text:'Drainage'},
			{value:'landscape_grading',text:'Grading/Leveling'},
			{value:'landscape_mowing',text:'Mowing'},
			{value:'landscape_artificial_turf',text:'Artificial Turf'},
		],
	},
	{
		isGroup: true,
		label: 'Planting🌳',
		options: [
			{value:'planting_trees_shrubs',text:'Trees / Shrubs'},
			{value:'planting_plants',text:'Plants'},
		],
	},
	{
		isGroup: true,
		label: 'Winter☃️',
		options: [
			{value:'winter_snow_removal',text:'Snow Removal'},
		],
	},
	{value:'other',text:'Other...'},
];

settings.recaptcha	=	{
	site_key	:	'6LexU_cZAAAAAFRC6Am7sI6gZ3BWUpkDg8w5F-fB',	//	Public:		Client-side Integration
};

settings.endpoints	=	{
//	api_base_url	:	'https://n9qx4ihxwe.execute-api.us-west-1.amazonaws.com',
	api_base_url	:	'https://api.blackstonevt.com',
};
settings.endpoints.forms	=	{
	verify_recaptcha	:	`${settings.endpoints.api_base_url}/recaptcha`,
	submit_contact		:	`${settings.endpoints.api_base_url}/contact`,
	submit_careers		:	`${settings.endpoints.api_base_url}/careers`,
};


export default settings;